const html = document.querySelectorAll('html');

document.querySelector('.header__burger').addEventListener('click', function() {

  html.forEach(item => {
    item.classList.add('menu-opened');
  })


  document.querySelector('.header__nav-mobile').classList.add('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.add('active');
})

document.querySelector('.header__nav-mobile--close').addEventListener('click', function() {
  document.querySelector('.header__nav-mobile').classList.remove('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.remove('active');

  html.forEach(item => {
    item.classList.remove('menu-opened');
  })
})



