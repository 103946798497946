
const btnsHandler = document.querySelectorAll('.map__btn-dot');
const btnsHandlerImg = document.querySelectorAll('.map__btn-dot img');
const sliderField = document.querySelectorAll('.map__slider-item');

const contactAddressItem = document.querySelectorAll('.contact__address-item img');

btnsHandler.forEach(item => {

  item.addEventListener('click', function(event){
    let parent = event.target.parentNode;
    let dataHandler = parent.dataset.tabsHandler;

    btnsHandlerImg.forEach(item => {
      item.src = 'img/map-loc.svg';
      event.target.src = 'img/contact-loc-full.svg';
    })

    sliderField.forEach(item => {
      item.classList.remove('active');

      let dataField = item.dataset.tabsField;

      if(dataHandler === dataField) {
        item.classList.add('active');
      }

      contactAddressItem.forEach(img => {
        img.src = 'img/contact-location_icon-1.svg';
        let dataIcon = img.dataset.tabsIcon;
        if(dataHandler === dataIcon) {
            img.src = 'img/contact-location_icon-2.svg';
          }
      })
    })

  })
})


function addMap(){
  const contactWrapper = document.querySelector('.contact__wrapper');

  contactWrapper.addEventListener('click', (event)=> {
    let target = event.target;

    if(target.closest('.contact__address-item')) {
      let parent = target.parentNode;
      let dataIcon = parent.dataset.tabsIcon;

      contactAddressItem.forEach(img => {
        img.src = 'img/contact-location_icon-1.svg';
        let dataIconImg = img.dataset.tabsIcon;
        if(dataIconImg === dataIcon) {
            img.src = 'img/contact-location_icon-2.svg';
          }
      })

      sliderField.forEach(item => {
        item.classList.remove('active');
        let dataField = item.dataset.tabsField;
        if(dataIcon === dataField) {
          item.classList.add('active');
        }
      })

      btnsHandlerImg.forEach(item => {
        let parent = item.parentNode;
        item.src = 'img/map-loc.svg';
        let dataHandler = parent.dataset.tabsHandler;
        if(dataIcon === dataHandler) {
          item.src = 'img/contact-loc-full.svg';
        }
      })



    }
  })
}


let currentUrl = window.location.pathname;
if (currentUrl.indexOf("index-contact.html") != -1) {
  addMap();
}


