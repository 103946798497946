// Подключение свайпера
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

const swiper = new Swiper('.slider__content', {
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.slider__slider-btn--next',
    prevEl: '.slider__slider-btn--prev',
  },
  pagination: {
    el: ".swiper-pagination",
  },
  scrollbar: {
    el: '.slider__scroll',
    draggable: true,
  },
  slidesPerView: 1.25,
  spaceBetween: 27,
  centeredSlides: true,
  // initialSlide: 1,
  loop: true,
  speed: 800,

  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: false,
      scrollbar: false,
    },
    1010: {
      slidesPerView: 1.25,
    },

  }
});




var menu = ['Slide 1', 'Slide 2', 'Slide 3']
const swiperMap = new Swiper('.map__swiper', {
  slidesPerView: 'auto',
  spaceBetween: 20,
  navigation: {
    nextEl: '.map__btn--next',
    prevEl: '.map__btn--prev',
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  loop: true,
  speed: 800,

});
