// console.log('components');

import './components/slider';
import './components/burger';
import './components/map';
import("./components/validation");
import("./components/form");
import("./components/menu");
import("./components/header");
import("./components/delivery");
import("./components/social");
